// Form styles
form.default {
    overflow: hidden;
    width: 100%;
    margin-bottom: rem(15px);

    @include breakpoint(medium) {
        padding-top: rem(20px);
    }

    @include breakpoint(large) {
        margin-bottom: rem(20px);
    }

    .anfahrt & {
        @include breakpoint(large) {
            margin-bottom: 0;
        }
    }

    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: $primary;
    }

    ::-moz-placeholder { /* Firefox 19+ */ 
        color: $primary;
    }

    :-ms-input-placeholder { /* IE 10+ */
        color: $primary;
    }

    :-moz-placeholder { /* Firefox 18- */
        color: $primary;
    }

    fieldset {
        border:none;
        margin:0 0 rem(15px) 0;

        @include breakpoint(large) {
            margin-bottom: 0;
        }

        .col {
            position: relative;
        }
    }

    label {
        color: inherit;
        display: block;
        padding: 0 0 rem(9px) 0;
        position:relative;
        hyphens: auto;
        font-size: rem(13px);
        line-height: rem(13px);
        font-weight: 700;

        @include breakpoint(large) {
            font-size: rem(14px); line-height: rem(14px);
        }

        &.error {
            animation: error 1s;
            color: $alert;
            margin: 0!important;
            padding: 0!important;
            text-align: right;
            position: absolute;
            bottom: rem(10px);
            right: rem(18px);
            background: none!important;
            font-weight: 300!important;
            font-size: rem(13px)!important;
            line-height: rem(13px)!important;
        }

        &[for="Datenschutz"], &[for="Rueckruf"] {
            float:left;
            background:lighten($secondary,3%);
            padding: rem(12px) rem(20px) rem(12px);
            width: 100%;
            font-weight: 300;
            font-size: rem(14px);
            line-height: rem(24px);
            margin-top: rem(3px);
            margin-bottom: rem(27px);

            @include breakpoint(large) {
                font-size: rem(16px);
                line-height: rem(28px);
            }

            &.error {
                bottom: rem(-16px);
                right: 0;
            }

            .ipad & {
                padding: rem(7px) rem(20px) rem(12px);

                input {
                    transform: translateY(5px);
                }
            }
        }

        &[for="Rueckruf"] {
            @include breakpoint(small) {
                margin-top: rem(22px);
            }

            @include breakpoint(large) {
                margin-top: rem(23px);
            }
        }
    }

    [type="checkbox"] {
        display: inline-block; 
        margin: 0 rem(10px) 0 0;
        width: auto;
        transform: translateY(1px);
    }

    [type="text"], [type="tel"], [type="email"], [type="file"], input, textarea, select {
        background: none;
        border: none;
        border-bottom: rem(1px) solid $border;
        border-left: rem(3px) solid $border;
        color: $primary;
        display: block;
        font-family: $mainFont;
        margin: 0 0 rem(27px) 0;
        padding: rem(11px) rem(18px) rem(12px);
        position: relative;
        resize: none;
        width:100%;
        transition: background 300ms ease-in-out, border 300ms ease-in-out;
        font-size: rem(14px); line-height: rem(24px);
        border-radius: 0;

        @include breakpoint(large) {
            font-size: rem(16px);
            padding: rem(13px) rem(18px) rem(14px);
        }

        &.req {
            border-left: rem(3px) solid $alert;
        }

        &:focus, &:hover {
            border-bottom: rem(1px) solid $color1;
            outline: none;
        }
    }

    span.selecting {
        position: relative; display: block;

        select {
            appearance: none; -webkit-appearance:none; -moz-appearance:none; -ms-appearance:none;
        }

        &:before {
            content: "";
            border-top: rem(10px) solid $dark;
            border-left: rem(7px) solid transparent;
            border-right: rem(7px) solid transparent;
            border-bottom: 0;
            position: absolute;
            right: rem(20px);
            top: 44%;
            width: 0;
            height: 0;
        }
    }

    .req {
        position: relative;

        &:before {
            content: ''; width: rem(3px); height: 100%; background:$alert; position: absolute; top: 0; left: 0;
        }
    }

    button, [type="submit"] {
       @extend %animatedTransform;
		background: $color1; border: none; color: $light; cursor: pointer; display: inline-block;font-size: rem(15px); line-height: rem(25px); padding: rem(10px) rem(30px); text-align: center; text-decoration: none; font-family: $mainFont; border-radius: rem(23px); font-weight: $mainFont;

		@include breakpoint(large) {
			font-size: rem($baseFontSize); line-height: rem($baseLineHeight);
		}

		@include breakpoint(full) {
			padding: rem(12px) rem(30px); border-radius: rem(25px); 
		}

		i {
			margin-left: rem(-5px);
		}

		&:hover, &:active, &:focus {
			background: $dark; color: $light;
		}
    }

    .errorContainer {
        position: relative;
    }
}

/*=VALIDATION */

@keyframes error {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@-webkit-keyframes error {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.specialfield {
    display: none !important;
    visibility:hidden !important;
}

#newsletter-info {
    background: $alert; color: $light; font-weight: bold; margin-bottom: rem(20px); padding: rem(15px) rem(25px); text-align: center;

    *:last-of-type {
        margin-bottom: 0;
    }
}

img[name=vimg] + input[name=imgverify] {
    float:right;
}