// Relativ path to images folder (used in the image-url() function)
$imagePath: "../images/" !default;

// +++++ Font settings
// Font size of main content (in px)
$baseFontSize: 18px;

// Line height of main content (in px)
$baseLineHeight: 26px;

// Headline font sizes (in px)
$h1Size: 44px;
$h2Size: 32px;
$h3Size: 32px;
$h4Size: 28px;
$h5Size: 20px;
$h6Size: 20px;

// Font stacks: $mainFont for simple content, $displayFont for headlines etc., $iconFont for icons
$mainFont:    "Assistant", sans-serif;
$displayFont: "$mainFont", sans-serif;
$iconFont:    "icomoon";

// +++++ Design & Layout
// Colours for your design.
$primary:   #505050;
$secondary: #eee;
$light:     #fff;
$medium:    #808080;
$dark:      #333;
$border:    #aaa;
$alert:     #eb0545;
$color1:    #eb0545;
$color2:    #ad0433;

// Horizontal padding left and right of grid columns
$baseGap: 18px;

// Grid row max-width
$rowMaxWidth: 1520px;

// Grid columns
$columnCount: 12;

// Standard border for your design
$baseBorder: 1px solid $border;

// Standart outline styles for focus states
$outline: 1px dotted $secondary;

// Assume a different base font size for breakpoint em calculation (default: 16px)
$bpContext: 16px;

// +++++ Miscellaneous
// text-indent value when using hideText(): "right" = positive value, "left" = negative value
$hideTextDirection: "right";

$iconMap: (
    times: "×",
    exclamation: "!",
    minus: "\f068",
    plus: "\f067",
    check: "\f00c",
    check-box: "\e958",
    close: "\f00d",

    map-marker: "\ea41", 
    phone: "\ea81",
    mobile: "\ea4b",
    mobile-swipe: "\e9e1",
    pencil: "\ea7a",
    paper-plane: "\ea6d",
    envelope: "\e9b0",
    clock: "\e968",

    pdf: "\f1c1",
    play: "\ea29",
    lens: "\ea16",
    basket: "\eace",
    star: "\eaf0",
    heart: "\ea00",

    angle-up: "\ea21",
    angle-right: "\ea1f",
    angle-down: "\ea1d",
    angle-left: "\ea1e"
);

/*  Grid Map 
    Define your breakpoints and grid classes below.
    Only generate the gridclasses you really need to reduce filesize.
    example:
    tiny: ( <-- "breakpoint name"
        width:  em(480px, $bpContext), <-- "breakpoint width"
        cols: (5,6,12), <-- "columns" result generates .tiny-5, .tiny-6 and .tiny-12
        prefix: (2,4), <-- "prefix" result generates .tiny-prefix-2 and .tiny-prefix-4
        suffix: (6,8), <-- "suffix" result generates .tiny-suffix-6 and .tiny-suffix-8
    )
*/
$gridMap: (
    default: (
        width:  em(320px, $bpContext),
        cols: (6,11)
    ),
    tiny: (
        width:  em(480px, $bpContext),
        cols: (6)
    ),
    small: (
        width:  em(640px, $bpContext),
        cols: (6)
    ),
    medium: (
        width:  em(760px, $bpContext),
        cols: (3,4,5,6,7,8,12)
    ),
    large: (
        width:  em(992px, $bpContext),
        cols: (3,4,5,6,7,8,10,12),
        prefix: (1)
    ),
    giant: (
        width:  em(1200px, $bpContext),
        cols: (2,4,6,8)
    ),
    huge: (
        width:  em(1364px, $bpContext),
        cols: (3,4,6,8)
    ),
    full: (
        width:  em(1520px, $bpContext),
        cols: (2,4,5,6,7,8)
    )
);