* {
  margin: 0; padding: 0;

  &, &:before, &:after {
    box-sizing: inherit;
  }
}

::selection {}
::-moz-selection {}

// DO NOT set font-size and line-height here!
// Adjust $baseFontSize and $baseLineHeight in _config.scss
html {
  background: $light;
  box-sizing: border-box;
  color: $primary;
  font-family: $mainFont;
  font-size: 100% * ($baseFontSize / 16px);
  hyphens: manual;
  line-height: rem($baseLineHeight);

  /* disable text resize in landscape. e.g. on iphone */
  text-size-adjust: none;
}

body {
  line-height: rem($baseLineHeight);
}

iframe, [data-iframe] {
  border: none; width: 100%;

  &[data-src] {
    @extend %lazyloading;
  }
}


/**
 * Headlines
 */

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    display: block;
    text-rendering: optimizeLegibility;
}

h1, .h1 {
    font-family: $mainFont; 
    font-size: rem(22px); 
    line-height: rem(26px); 
    color: $alert; 
    font-weight: 500;
    letter-spacing: .1em;
    text-transform: uppercase;
    margin-bottom: rem(20px);
    position: relative;

    @include breakpoint(medium) {
      padding-left: rem(100px);
      font-size: rem(24px); 
      line-height: rem(28px); 
    }

    @include breakpoint(large) {
      font-size: rem(30px);
      line-height: rem(32px);
      margin-bottom: rem(30px);
    }

    @include breakpoint(giant) {
      font-size: rem(34px);
      line-height: rem(36px);
      margin-bottom: rem(40px);
    }

    @include breakpoint(huge) {
      font-size: rem(40px);
      line-height: rem(42px);
      margin-bottom: rem(50px);
    }

    @include breakpoint(full) {
      font-size: rem(44px);
      line-height: rem(46px);
      margin-bottom: rem(60px);
    }

    span {
        display: block; 
        font-size: rem(17px); 
        line-height: rem(22px);  
        font-weight: 300;
        text-transform: none;
        letter-spacing: 0;

        @include breakpoint(medium) {
          font-size: rem(18px); 
          line-height: rem(28px); 
        }

        @include breakpoint(large) {
          font-size: rem(20px);
        }

        @include breakpoint(giant) {
          font-size: rem(22px);
        }

        @include breakpoint(huge) {
          font-size: rem(23px);
        }

        @include breakpoint(full) {
          font-size: rem(24px);
        }      
    }

    &:before {

    @include breakpoint(medium) {
      content: '';
      background: $alert;
      width: rem(80px);
      height: rem(1px);
      position: absolute;
      bottom: rem(8px); left: 0;
    }
  }
}

h2, .h2, h3, .h3 {
    font-family: $mainFont; 
    font-size: rem(20px); 
    line-height: rem(26px); 
    color: $alert; 
    font-weight: 500;
    letter-spacing: .1em;
    text-transform: uppercase;
    margin-bottom: rem(20px);
    position: relative;

    @include breakpoint(medium) {
      padding-left: rem(100px);
      font-size: rem(22px); 
      line-height: rem(26px); 
    }

    @include breakpoint(large) {
      font-size: rem(26px);
      line-height: rem(30px);
      margin-bottom: rem(30px);
    }

    @include breakpoint(giant) {
      font-size: rem(28px);
      line-height: rem(30px);
      margin-bottom: rem(40px);
    }

    @include breakpoint(huge) {
      font-size: rem(30px);
      line-height: rem(32px);
      margin-bottom: rem(50px);
    }

    @include breakpoint(full) {
      font-size: rem(32px);
      line-height: rem(34px);
      margin-bottom: rem(60px);
    }

    &:before {

        @include breakpoint(medium) {
          content: '';
          background: $alert;
          width: rem(80px);
          height: rem(1px);
          position: absolute;
          bottom: rem(8px); left: 0;
        }
      }

    span {
        display: block; 
        font-size: rem(16px); 
        line-height: rem(22px);  
        font-weight: 300;
        text-transform: none;
        letter-spacing: 0;

        @include breakpoint(medium) {
          font-size: rem(17px); 
          line-height: rem(26px); 
        }

        @include breakpoint(large) {
          font-size: rem(20px);
        }

        @include breakpoint(giant) {
          font-size: rem(20px);
        }

        @include breakpoint(huge) {
          font-size: rem(22px);
        }   
    }
}

h4, .h4 {
    @extend .marginBottom; 
    font-size: rem(18px); 
    line-height: rem(28px); 
    font-weight: 500;
    color: $primary;

    @include breakpoint(large) {
        font-size: rem(22px); 
        line-height: rem(32px);     
    }
}

h5, .h5 {
    @extend .marginBottom; 
    font-size: rem(17px); 
    line-height: rem(27px); 
    font-weight: 500;

    @include breakpoint(large) {
        font-size: rem(20px); 
        line-height: rem(30px);     
    }
}

h6, .h6 {
    @extend .marginBottom; 
    font-size: rem(16px); 
    line-height: rem(26px); 
    font-weight: 500;

    @include breakpoint(large) {
        font-size: rem(18px); 
        line-height: rem(28px);     
    }
}


/**
 * Text
 */

.content p, .content address, .content ul, .content ol, .content dl, .content table, .marginBottom {
    margin-bottom: rem(20px);
}

.content p, .content address, .content ul, .content ol, .content table td, .content dt, .content dd, form.default label, form.default input, form.default textarea, form.default select {
    font-size: rem($baseFontSize); line-height: rem($baseLineHeight);
}

strong, b {
    font-weight: 700;
}

address {
    font-style: normal;
}

.nowrap {
    white-space:nowrap;
}

.upper {
    text-transform:uppercase;
}

.taCenter {
    text-align: center;
}

.taRight {
    text-align: right;
}


/**
 * Links
 */

a {
  color: $alert; 
  font-weight: 300;
  transition: color 300ms ease-in-out; 
  word-wrap: break-word;

  &:focus, &:hover, &:active {
    color: $primary; 
    text-decoration: none;
  }

  .content & {
    font-weight: 500;
  }

  img {
    border: none;
  }

  &[href^="tel"] {
    color: inherit; text-decoration: underline;
  }
}

#privacyPolicy {
  a {
    display: inline-block; 
    word-break: break-all;
  }
}

a:not(.btn):focus, [tabindex]:focus {
  outline: $outline; outline-offset: rem(5px);
}

hr, .hr {
  height: rem(1px); 
  width: rem(100px); 
  border: none; border-bottom: $baseBorder; 
  clear: both; margin:0 auto rem(25px);
  padding-bottom:rem(10px);
   display: block;

    @include breakpoint(small) {
        margin-bottom:rem(35px); 
        padding-bottom:rem(20px);
    }

    @include breakpoint(large) {
        margin-bottom:rem(50px); 
        padding-bottom:rem(30px);
    }

    @include breakpoint(giant) {
        margin-bottom:rem(80px); 
        padding-bottom:rem(60px);
    }

     @include breakpoint(huge) {
        margin-bottom:rem(100px); 
        padding-bottom:rem(80px);
    }
    
    &.clear { 
         height:0; width:0; background:none; border: none; margin:0; padding: 0;
    }
}

ul, ol {
  list-style: none;

  .content & {
    margin-bottom: 0;

        li {
            padding:0 0 rem(10px) rem(25px); 
            position: relative;

            &:before {
                @extend .icon; 
                @extend .icon-check; 
                color: $primary; 
                position: absolute; 
                top: rem(5px); left: rem(0px); 
            }
        }

        ul {
            margin-left: 1rem;
            margin-bottom: 0;
        }
    }
}

.content dl {
  @include pie-clearfix;

    dt, dd {
        display: block; background:$secondary;
    }

    dt {
        padding: rem(15px) rem(20px) 0; font-weight: 600;

        @include breakpoint(large) {
            padding: rem(15px) rem(25px) 0;
        }
    }

    dd {
        padding:0 rem(20px) rem(15px); margin-bottom: rem(10px);

        @include breakpoint(large) {
            padding:0 rem(25px) rem(15px); margin-bottom: rem(20px);
        }
    }
}

.content ol {
  list-style-type: none; counter-reset: item;

  li {
    padding-top: 0.3rem; padding-bottom: 0.3rem; display: table; counter-increment: item;

    &:before {
      content: counters(item, ".") ". "; display: table-cell; padding-right: 0.3em;
    }
  }

  ol {
    margin-bottom: 0;

    li {
      &:before {
        content: counters(item, ".") " ";
      }
    }
  }
}

/*
 * responsive images
 */

img {
  vertical-align: bottom;
  float: none;
  height: auto;
  max-width: 100%;
  width: 100%;

  &[src^='http://cms.'], &[src^='https://cms.'] {
    max-width: none;
    width: auto;
  }

  [data-rel] &,
  .lightbox-image & {
    margin-bottom: 0;
  }

  &[data-src] {
    @extend %lazyloading;
  }
}

/*
 * reset copyright link
 */
[href*="alpen-web.ch"],
[href*="bewertet.de"],
[href*="euroweb.at"],
[href*="euroweb.de"],
[href*="ew.de"],
[href*="geprueft.de"],
[href*="web2walk.com"],
[href*="webstyle.de"],
[href*="webstyle.com"],
[href*="wn-onlineservice.de"],
[href*="internet-media.com"],
[href*="yourrate.com"] {
  display: table;
  text-decoration: none;

  img {
    background: none;
    border-radius: 0;
    border: none;
    margin-bottom: rem(5px);
    outline: none;
    padding: 0;
    white-space: normal;
    width: auto !important;
  }
}

/**
 * Tables
 */

table {
  display: table;
  border-collapse: collapse;
  width: 100%;
  max-width: 100%;
  text-align: left;
  font-size: rem($baseFontSize);

  @include breakpoint(small, max) {
    overflow-x: auto;
    display: block;
  }

  caption {
    text-align: left;
    padding: rem($baseGap) rem($baseGap) rem(25px);
    display: table-caption;
    font-weight: 700;
    font-size: 1.2rem;
  }

  thead {
    border-collapse: collapse;
    display: table-header-group;
  }

  tbody {
    width: 100%;
    overflow-x: scroll;
    display: table-row-group;

    tr {
      padding: rem($baseGap) 0;

      &:not(:last-child) {
        border-bottom: rem(1px) solid $medium;
      }
    }
  }

  tr {
    display: table-row;
  }

  th,
  td {
    padding: rem($baseGap);
    display: table-cell;
    vertical-align: top;
  }

  @include breakpoint(small, max) {
    td {
      min-width: 50vw;
    }
  }
}
