// GLOBAL SETTINGS
//////////////////////////////////////////////

$break: large; // Breakpoint – switch to Desktop version

$desktopHover: true; // Set Hover to reach Submenus for Desktop (true, false)

// NAVIGATION
//////////////////////////////////////////////

$navBg: darken($secondary,5%); // Navigation Background
$navColor: $dark; // Navigation Link Color

@mixin navButton {
	color: $primary;
	text-decoration: none;
}
%buttonHover {
	// Link Hover
	color: $light;
	background-color: $color1;
}
%buttonActive {
	// Link Active
	color: $light;
	background-color: $color1;
}
%prevIcon {
	// Icon to previus Submenu
	font-family: $iconFont;
	content: map-get($iconMap, angle-right);
	transform: rotateY(180deg);

	@include breakpoint($break) {
		transform:rotate(90deg);
	}
}
%nextIcon {
	// Icon to next Submenu
	font-family: $iconFont;
	content: map-get($iconMap, angle-right);

	@include breakpoint($break) {
		transform:rotate(90deg);
	}
}
$closeIcon: map-get($iconMap, times); // Icon to close Submenu

// Quickbar
//////////////////////////////////////////////

$barHeight: 50px; // height (in px)
$barPos: top; // position (top, bottom)
$barHide: true; // if bar hides on scroll (true, false)

$barBg: $color1; // background-color
$barColor: $light; // children colors – texts and burgerbutton

//////////////////////////////////////////////
// NAVIGATION FUNCTIONALITY
//////////////////////////////////////////////

@import "../modules/ruffnav";

// Navigation Styles
////
@include pageNavi {
	background-color: $navBg;
	z-index: 1001;
	border: rem(30px) solid darken($secondary,5%);
	border-top: none;
	width: 100%;
    right: 0;
    top: 0;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    transition: all 300ms ease-in-out;

    @include breakpoint(small) {
    	width: rem(370px);
    }

	.naviContact {
		padding: rem(80px) 0 rem(30px); text-align: center; border-bottom: $baseBorder; 

		@include breakpoint(small) {
			text-align: left; padding-top: rem(30px);
		}

		> span {
			display: block; font-size: rem(16px); line-height: rem(22px);

			b {
				display: block; text-transform: uppercase; font-weight: 700; margin-top: rem(10px); letter-spacing: .2em;
			}

			a {
				text-decoration: none; color: $primary;
			}
		}
	}

	nav {
		@include ul {
			&.navi {
				li {
					border-bottom: $baseBorder; font-weight: 700;

					a, span {
						padding: rem(12px) rem(15px); 
					}

					span {
						&:after {
							font-weight: 400;
						}
					}

					&.nav-back {
						background:darken($secondary,10%); font-weight: 300; text-transform: uppercase; letter-spacing: .2em; padding: rem(12px) rem(15px)!important; font-size: rem(14px);

						&:before {
							font-weight: 400; margin-right: 0!important;
						}
					}

					@include navButtons {
						@include navButton;
					}

					@include hasSub {
						@include sub {
							background: $navBg;

							&:before {
								padding: 1em rem($baseGap);
								background-color: darken($navBg, 6%);
							}
						}
					}

					@include navBack {
					}
				}
			}
		}
	}
}

// Navigation Desktop Styles
////
@include breakpoint($break) {
	@include desktopVersionFunctionality;

	#pageNavi {
		background-color: $light; 
		border: none; 
		width: 100%; 
		position: absolute; 
		padding: 0 rem(18px); 
		overflow-y: visible; 
		top: 0; transition: none;		


		body.scroll & {
			position: fixed; 
			top: rem(-1px); 
			box-shadow: 0 0 rem(20px) 0 rgba(black,0.2); 
			animation:fadeDown 1200ms ease-in-out;

			@keyframes fadeDown {
			    from {
			        top:-200px; opacity:0;
			    }

			    to {
			        top:rem(-1px); opacity:1;
			    }
			}
		}
		
		.naviContact {
			display: none;
		}

		nav {
			padding: rem(10px) 0; 
			

			@include ul {

				&.navi {
					justify-content: flex-end;

					li {
						border-bottom: none; 
						padding: 0;
						font-weight: 300;

						&.datenschutzerklaerung, &.impressum, &.jobs, &.linksPartner {
							display: none;
						}

						a, span {
							padding: rem(10px) rem(20px); 
						}

						span {
							&:after {
								transform:rotate(90deg) translateX(2px)!important;
							}
						}

						&.nav-back {
							color: $primary!important; 
							background:darken($secondary,5%); 
							padding: rem(11px) rem(15px) rem(8px)!important; 
							font-size: rem(18px); 
							overflow: hidden;

							&:before {
								font-weight: 700;
							}

							&:hover, &.active {
								background:darken($secondary,15%)!important;
							}
						}

						&.hasSub {
							.sub {
								background:$secondary;

								li {
									transition: background 300ms ease-in-out; font-weight: 300; border-top: $baseBorder; letter-spacing: 0; font-size: rem(16px);

									&:nth-child(1), &:nth-child(2) {
										border-top: none;
									}

									&:hover, &.active {
										background:darken($secondary,10%);
									}
								}
							}
						}

						&:hover, &.active {
							> a, > span {
								background: none!important; color: $color1!important;
							}
						}

						span.active, span:hover {
							background: none!important; color: $color1!important;
						}
					}
				}
			}
		}
	}
}

@include breakpoint(giant) {

	#pageNavi {

		.naviWrapper {
			display: flex;
			justify-content: space-between;
			align-items: center;
			max-width: rem(1484px);
			margin: 0 auto;
		}

		.naviContact {
			padding: 0; 
			text-align: left; 
			border-bottom: none; 
			display: block;

			> span {
				display: inline-block;
				margin-right: rem(15px);
				font-weight: 500;
				color: $alert;

				&.write {
					display: none;
				}
			}
		}
	}
}

@include breakpoint(huge) {
	
	#pageNavi {

		.naviContact {
			
			> span {

				&.write {
					display: inline-block;
					font-weight: 500;
					color: $alert;

					a {
						color: $alert;
						font-weight: 500;

						&:hover {
							text-decoration: underline;
						}
					}
				}
			}
		}
	}
}

@include breakpoint(full) {

	#pageNavi {
		top: 0;

		.naviContact {
			
			> span {
				font-size: rem(18px);
			}
		}

		nav {
			padding: rem(22px) 0;

			@include ul {
				&.navi {
					li {
						font-size: rem(19px);
					}
				}
			}
		}
	}
}

// Navigation Add Styles
////

.naviAdd {
	display: none;

	@include breakpoint(large) {
		display: block; 
		padding: rem(20px) 0; 
		text-align: right;
	}

	@include breakpoint(full) {
		padding: rem(30px) 0;
	}

	li {
		display: inline-block; 
		font-size: rem(16px); 
		line-height: rem(26px); 
		font-weight: 500; 
		text-transform: uppercase; 
		letter-spacing: .2em; 
		margin: 0 rem(20px);

		@include breakpoint(large) {
			text-transform: none;
			font-weight: 300;
			letter-spacing: 0;
			font-size: rem(18px); 
			line-height: rem(26px);
			margin-right: 0;
			margin-left: rem(30px)
		}

		@include breakpoint(full) {
			font-size: rem(19px);
		}

		a {
			display: block; 
			color: $primary; 
			text-decoration: none;
		}

		&:hover, &.active {
			a {
				color: $color1;
			}
		}
	}
}

// Quickbar Styles
////
#quickbar {
	@extend %animatedTransform;
	background-color: $barBg; color: $barColor; width: rem(50px); height: rem(50px); z-index: 1002; display: flex; 	justify-content: space-between; align-items: center; padding: rem(15px); position: fixed; top: 0; left: 50%; transform: translateX(-25px);

	@include breakpoint(small) {
		left: auto; right: 0; transform: translateX(0px);
	}

	@include breakpoint($break) {
		display: none;
	}
}
