// if you want to use more file types, write: $type: woff ttf woff2 (no comma separation!)

@include font-face(
 $name: "icomoon",
 $file: "icomoon",
 $weight: 400,
 $style: normal,
 $type: woff
);

@include font-face(
 $name: "assistant",
 $file: "assistant-v3-latin-300",
 $weight: 300,
 $style: normal,
 $type: woff2 woff
);

@include font-face(
 $name: "assistant",
 $file: "assistant-v3-latin-600",
 $weight: 600,
 $style: normal,
 $type: woff2 woff
);
