
// GLOBAL
//////////////////////////////

.flexCenter {
	justify-content: center;
}


// HEADER
//////////////////////////////

header {

	.headerMain {	

		.headerMainBG {
			padding-top: rem(80px);
			padding-bottom: rem(40px);
			background: image-url('layout/bgHeader.jpg') center center no-repeat;
			background-size: cover;
			position: relative;
			color: $light;
			font-family: $mainFont;
			font-weight: 300;

			@include breakpoint(large) {
				padding-top: rem(120px);
				padding-bottom: rem(50px);
			}

			@include breakpoint(giant) {
				padding-top: rem(140px);
				padding-bottom: rem(70px);
			}

			@include breakpoint(full) {
				padding-top: rem(190px);
				padding-bottom: rem(80px);
			}

			&:before {
				content: '';
				background: rgba($dark, .6);
				width: 100%;
				height: 100%;
				position: absolute;
				top:0; left: 0;
			}
		}

		.claim {
			display: block;
			margin-top: rem(20px);
			margin-bottom: rem(20px);
			text-align: center;
			font-size: rem(18px);
			line-height: rem(24px);
			position: relative;

			@include breakpoint(medium) {
				text-align: left;
				padding-left: rem(100px);
				margin: rem(40px) 0;
			}

			@include breakpoint(giant) {
				font-size: rem(20px);
				line-height: rem(26px);
				margin-top: rem(120px);
			}

			@include breakpoint(full) {
				font-size: rem(22px);
				line-height: rem(28px);
				margin-top: rem(180px);
			}

			&:before {

				@include breakpoint(medium) {
					content: '';
					background: $light;
					width: rem(80px);
					height: rem(1px);
					position: absolute;
					bottom: rem(5px); left: 0;
				}				
			}

			b {
				font-weight: 500;
				text-transform: uppercase;
				display: block;
				font-size: rem(24px);
				line-height: rem(28px);
				letter-spacing: 0.1em;
				margin-bottom: rem(10px);

				@include breakpoint(full) {
					font-size: rem(32px);
				}
			}			
		}

		.centerMe {

			text-align: center;
			margin-top: rem(20px);

			@include breakpoint(medium) {
				text-align: left;
			}

			@include breakpoint(giant) {
				margin-top: rem(40px);
			}

			.btn {
				display: inline-block;
				font-size: rem(14px);

				@include breakpoint(tiny) {
					font-size: rem(16px);
				}

				@include breakpoint(medium) {
					font-size: rem(18px)
				}
			}

			.marginleft {
				margin-left: rem(15px);

				@include breakpoint(medium) {
					margin-left: rem(25px);
				}
			}
		}


		.branding {
			max-width: rem(300px); 
			width: 100%; 
			margin: 0 auto; 
			display: block;
			position: relative;
			z-index: 3;

			@include breakpoint(medium) {
				margin-left: 0;
			}

			@include breakpoint(small) {
				max-width: rem(341px);
			}

			img {
				width: 100%;
			}
		}
	}
}



// MAIN CONTENT
//////////////////////////////

.content {
	padding-top: rem(60px);
	padding-bottom: rem(40px);

	@include breakpoint(large) {
		padding-top: rem(120px);
		padding-bottom: rem(50px);
	}

	@include breakpoint(giant) {
		padding-top: rem(135px);
	}

	@include breakpoint(huge) {
		padding-top: rem(155px);
	}

	@include breakpoint(full) {
		padding-top: rem(175px);
	}

	// TEASER
	//////////////////////////////

	.teaserArea {
		display: block;
		margin: rem(60px) 0 rem(80px);

		@include breakpoint(full) {
			margin: rem(100px) 0 rem(120px); 
		}
		
		.flexIt {
			display: flex;
			justify-content: space-between;

			a {				
				align-items: center;
				justify-content: center;
				position: relative;
				height: rem(200px);
				text-decoration: none;
				display: flex;
				margin-bottom: rem(40px);				

				&:before {
					content: '';
					background: rgba($dark, .3);
					width: 100%;
					height: 100%;
					position: absolute;
					top:0; left: 0;
					transition: all 300ms ease-in-out;
				}

				&:hover {

					span {
						background: $dark;
						color: $light;
					}

					&:before {
						background: rgba($dark, .6);
					}
				}

				&.bgBaudienst {
					background: image-url('layout/bgBaudienst.jpg') center center no-repeat;
					background-size: cover;

					@include breakpoint(medium) {
						height: auto;
						width: 100%;
					}
				}

				&.bgGartendienst {
					background: image-url('layout/bgGartendienstleistungen.jpg') center center no-repeat;
					background-size: cover;

					@include breakpoint(medium) {
						height: rem(200px);
					}

					@include breakpoint(large) {
						height: rem(300px);
					}

					@include breakpoint(giant) {
						height: rem(420px);
					}
				}

				&.bgFuhrpark {
					background: image-url('layout/bgFuhrpark.jpg') center center no-repeat;
					background-size: cover;

					@include breakpoint(medium) {
						height: rem(200px);
					}

					@include breakpoint(large) {
						height: rem(300px);
					}

					@include breakpoint(giant) {
						height: rem(420px);
					}
				}

				span {
					background: $alert;
					padding: rem(20px); 
					position: relative;
					z-index: 3;
					font-size: rem(16px);
					font-weight: 300;
					text-transform: uppercase;
					color: $light;	
					transition: all 300ms ease-in-out;				

					@include breakpoint(tiny) {
						padding: rem(20px) rem(15px);
						font-size: rem(13px);
					}

					@include breakpoint(small) {
						padding: rem(20px);
						font-size: rem(15px);
					}

					@include breakpoint(medium) {
						padding: rem(15px) rem(20px);
						font-size: rem(16px);
					}

					@include breakpoint(large) {
						padding: rem(20px) rem(25px);
						font-size: rem(18px);
					}

					@include breakpoint(giant) {
						padding: rem(20px);
						font-size: rem(20px);
					}

					@include breakpoint(full) {
						padding: rem(30px);
						font-size: rem(22px);
					}					
				}
			}

			.stretchMe {

				@include breakpoint(medium) {
					display: flex;
					align-items: stretch;
				}
			}
		}

	}

	.pAfterList {
		margin-top: rem(30px);

		@include breakpoint(giant) {
			margin-top: rem(40px);
		}
	}
	
	.containerMargin {
		margin: rem(60px) 0 rem(80px);

		@include breakpoint(huge) {
			margin: rem(100px) 0 rem(120px);
		}

		img {
			margin-bottom: rem(30px);

			@include breakpoint(medium) {
				margin-bottom: 0;
			}

			&:hover {
				opacity: .8;
				transition: all 300ms ease-in-out;
			}
		}
	}

	.imageStyles {

		margin-bottom: rem(30px);

			&:hover {
				opacity: .8;
				transition: all 300ms ease-in-out;
			}
		}

	.floatRight {

		@include breakpoint(small) {
			text-align: right;
		}		

		@include breakpoint(medium) {			
			margin-top: rem(20px);
		}

		@include breakpoint(giant) {
			margin-top: rem(40px);
		}
	}

	.googleMaps {
		@extend .videoContainer;

		@include breakpoint(medium) {
			height: rem(400px);
		}
	}
}



// FOOTER
//////////////////////////////

footer {
	.footerMain	{

	}

	.footerBottom {

	}
}